<template>
  <div class="conr">
    <div class="conr-bot ">
      <el-button type="primary" @click="signup">我要报名</el-button>
      <!-- zgys资审公告 -->
      <el-timeline :reverse="reverse" v-if="categoryid=='zgys'">
          <el-timeline-item>
            <h3>公告发布时间</h3>
            <p>开始：{{infocontent.Data_ggfbtime}}</p>
          </el-timeline-item>
            <el-timeline-item >
            <h3>公告截止时间</h3>
            <p>开始：{{infocontent.Data_ggendtime}}</p>
          </el-timeline-item>
         <el-timeline-item >
            <h3>文件获取时间</h3>
            <p>开始：{{infocontent.Data_wjhqsj}}</p>
          </el-timeline-item>
           <el-timeline-item>
            <h3>文件递交截止时间</h3>
            <p>开始：{{infocontent.Data_wjdjjztime}}</p>
          </el-timeline-item>           
     </el-timeline>
      <!-- bggg:变更公告  zbgg：招标公告 -->
      <el-timeline :reverse="reverse" v-if="categoryid=='bggg' ||categoryid=='zbgg' ">
          <el-timeline-item >
            <h3>公告发布时间</h3>
            <p>开始：{{infocontent.Data_ggfbtime}}</p>
          </el-timeline-item>
         <el-timeline-item >
            <h3>文件获取时间</h3>
            <p>开始：{{infocontent.Data_fsks}}</p>
          </el-timeline-item>
           <el-timeline-item>
            <h3>文件递交截止时间</h3>
            <p>开始：{{infocontent.Data_fsjz}}</p>
          </el-timeline-item>
            <el-timeline-item>
            <h3>响应文件截止时间</h3>
            <p>开始：{{infocontent.Data_tjjz}}</p>
          </el-timeline-item>
           <el-timeline-item >
            <h3>解密截止时间</h3>
            <p>开始：{{infocontent.Data_jmjz}}</p>
          </el-timeline-item>
          
     </el-timeline>
      <!-- bydy：补遗答疑 -->
      <el-timeline :reverse="reverse" v-if="categoryid=='bydy'">
          <el-timeline-item>
            <h3>公告发布时间</h3>
            <p>开始：{{infocontent.Data_ggfbtime}}</p>
          </el-timeline-item>
         <el-timeline-item >
            <h3>投标文件递交截止时间</h3>
            <p>开始：{{infocontent.Data_tjjz}}</p>
          </el-timeline-item>
           <el-timeline-item>
            <h3>解密截止时间</h3>
            <p>开始：{{infocontent.Data_jmjz}}</p>
          </el-timeline-item>
          
     </el-timeline>
       <!--hxrgs：候选人公示 -->
      <el-timeline :reverse="reverse" v-if="categoryid=='hxrgs'">
            <el-timeline-item>
            <h3>候选人公告发布时间</h3>
            <p>开始：{{infocontent.Data_ggfbtime}}</p>
          </el-timeline-item>
     </el-timeline>
      <!--ycgg：异常公告 -->
      <el-timeline :reverse="reverse" v-if="categoryid=='ycgg'">
            <el-timeline-item>
            <h3>公告发布时间</h3>
            <p>开始：{{infocontent.Data_ggfbtime}}</p>
          </el-timeline-item>
     </el-timeline>
       <!--zhongbiaogg：中标公告 -->
      <el-timeline :reverse="reverse" v-if="categoryid=='zhongbiaogg'">
            <el-timeline-item>
            <h3>中标公告时间</h3>
            <p>开始：{{infocontent.Data_ggfbtime}}</p>
          </el-timeline-item>
     </el-timeline>
    </div>
  </div>
</template>

<script>
export default {
  name: "conr",
  props:['infocontent','categoryid'],
  data() {
      return {
        reverse: false,
        activities: [{
          content: '报名时间',
          startime: '2021-10-23 09:00',
          endtiem:'2021-10-28 17:00'
        }, {
          content: '文件下载时间',
           startime: '2021-10-23 09:00',
          endtiem:'2021-10-28 17:00'
        }, {
          content: '招标文件售卖时间',
          startime: '2021-10-23 09:00',
          endtiem:'2021-10-28 17:00'
        }, {
          content: '投标时间',
          startime: '2021-10-23 09:00',
          endtiem:'2021-10-28 17:00'
        }, {
          content: '开标时间',
          startime: '2021-10-23 09:00',
          endtiem:'2021-10-28 17:00'
        }]
      };
    },
  methods: {
    signup(){
      let url = this.com.loginurl[2].url
       window.open(url,'_blank')
    }
  
  },
};
</script>

<style  lang="less"  scoped>
.conr {
  width: 230px;
}
.conr-bot{
 padding-bottom: 20px;
 .el-button{
   width: 100%;
 }
}
 .el-timeline{
   margin: 20px 10px;
   h3{
     font-size: 14px;
     color: @bule;
     margin-bottom:5px;
   }
   p{
    font-size: 14px;
     color: @hui; 
   }
    ::v-deep .el-timeline-item__wrapper{
   text-align: left;
 } 
 }


</style>
<template>
  <div class="del mywidth">
    <div  class="box-bg">
    <!-- <el-page-header class="pagetop" @back="goBack" content="详情页面"></el-page-header> -->
    <el-breadcrumb separator-class="el-icon-arrow-right" class="pagetop">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
       <el-breadcrumb-item :to="{ path: '/trans' }">交易信息</el-breadcrumb-item>
      <el-breadcrumb-item>{{infocontent.title_page}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="con btw-l">
      <del-l :infocontent="infocontent" :loading='loading' :categoryid='this.query.type'></del-l>
      <del-r :infocontent='infocontent' :categoryid='this.query.type'></del-r>
    </div>
    </div>
  </div>
</template>

<script>
import { del } from "@/api/trans.js";
import DelL from "./com/delL";
import DelR from "@components/my-del-r";
export default {
  name: "TransDel",
  components: {
    DelL,
    DelR,
  },
  data() {
    return {
      query: {},
      infocontent: {},
      loading:true
    };
  },
  mounted() {
    this.query.id = this.$route.query.id; //路由参数
    this.query.type = this.$route.query.type;
    this.getdel();
  },
  methods: {
    getdel() {
      this.loading = true
      del(this.query).then((data) => {
        this.infocontent = data.tdata;
        this.loading = false
      });
    },
  },
};
</script>

<style lang="less" scoped>
.del {
  margin: 20px auto;
}
.pagetop {
  border-bottom: 1px solid @border-color;
  padding: 0 0 10px;
  margin: 10px 0;
}
::v-deep .el-page-header__content {
  font-size: 15px;
}
.con {
  padding: 10px 0;
  .conl {
    width: 835px;
  }
}
</style>
<template>
  <div class="del-l delcon">
    <div class="conl-bot  " v-loading='loading'>
      <div class="tit">{{infocontent.Data_title}}</div>
      <div class="xtit">
          <span style="height: 10px; width: 5px;background: #2196f3;border-radius: 5px;position: absolute;left: 0;top: 10px;"></span>
          项目概况</div>
      <div class="del-mid row-wrap">
         <div class="item">项目编号<span>{{infocontent.zbprocode}}</span></div>
         <div class="item">招标方式<span>{{infocontent.zbfsname}}</span></div>
         <div class="item">项目地点<span>{{infocontent.cityname}}</span></div>
         <div class="item">所属行业<span>{{infocontent.hyname}}</span></div>
         <div class="item" style="width:100%">招标项目名称<span>{{infocontent.zbproname}}</span></div>
      </div>
       <!-- <div class="xtit">内容公告 </div> -->
      <div class="del  " v-html="infocontent.Data_content"> </div>

      <div class="del" v-html="infocontent.htmla"> </div>

                <div class="gg_lies">
                    <!-- <p class="liehh" v-if="infocontent.liDyCq">补遗答遗：</p> -->
                    <p class="bgtit" v-if="infocontent.liDyCq" style="padding-left: 15px; color: #2196f3;margin:0 auto;position: relative;height: 30px;line-height: 30px;font-weight: bold;border-bottom: 3px solid #2196f3;font-size: 15px;"><span style="height: 10px; width: 5px;background: #2196f3;border-radius: 5px;position: absolute;left: 0;top: 10px;"></span>补遗答遗</p>
                   <div class="del-mid">
                   <p v-if="infocontent.liDyCq==''">本公告没有补遗答遗！</p>
                  <!-- :href="'/hbiddingWeb/pages/jyinfo/detail.html?infoid='+v.GGCODE+'&categoryid=bydy'+'&active=0'"  -->
                    <a

                    v-for="(v,i) in infocontent.liDyCq"
                    :key="i"
                    @click="del(v.GGCODE,'bydy')"
                    >
                        {{v.GGNAME}} （{{v.GGFBTIME}}）
                    </a>
                    </div>

                    <p class="bgtit" v-if="infocontent.liYcgg" style="padding-left: 15px; color: #2196f3;margin:0 auto;position: relative;height: 30px;line-height: 30px;font-weight: bold;border-bottom: 3px solid #2196f3;font-size: 15px;"><span style="height: 10px; width: 5px;background: #2196f3;border-radius: 5px;position: absolute;left: 0;top: 10px;"></span>异常公告</p>
                    <div class="del-mid">
                        <p v-if="infocontent.liYcgg==''">本公告没有异常公告！</p>
                            <a
                            v-for="(v,i) in infocontent.liYcgg"
                            :key="i"
                            @click="del(v.GGCODE,'ycgg')"
                            >
                                {{v.GGNAME}} （{{v.GGFBTIME}}）
                            </a>
                    </div>
                </div>
                <table v-if="categoryid=='hxrgs' && !ext3" class="fjly">
                    <tr>
                        <th>否决投标单位及理由</th>
                        <td  v-if="fjdwjly">
                            <div style="width: 765px" v-html="fjdwjly"></div>
                        </td>
                    </tr>
                    <tr>
                        <th>提出异议渠道和方式</th>
                        <td v-if="ext_complaints">
                            <div style="width: 765px" v-html="ext_complaints"></div>
                        </td>
                    </tr>
                    <tr>
                        <th>全部投标单位</th>
                        <td v-if="qbtbdw">
                            <div style="width: 765px" v-html="qbtbdw"></div>
                        </td>
                    </tr>
                </table>

                <!-- 招标文件下载 -->
                <div v-if="infocontent.zbfilelist">
                    <p class="bgtit" v-if="infocontent.zbfilelist.length" style="padding-left: 15px; color: #2196f3;margin:0 auto;position: relative;height: 30px;line-height: 30px;font-weight: bold;border-bottom: 3px solid #2196f3;font-size: 15px;"><span style="height: 10px; width: 5px;background: #2196f3;border-radius: 5px;position: absolute;left: 0;top: 10px;"></span>招标文件</p>

                    <div v-if="infocontent.zbfilelist.length"  style="width:92%;color:red;margin:0 auto;">
                        1.招标文件获取期限结束后将不能下载招标文件，请在下载完成后及时使用“投标文件制作工具”打开yczb格式招标文件，可查看招标文件正文即为完整有效。
                        <br/>
                        2.需妥善保存好验证过的“有效yczb格式招标文件”，没有“有效yczb格式招标文件”将无法制作投标文件。
                        <br/>
                        3.全流程电子标.yczb格式招标文件请务必下载，否则将无法制作投标文件。
                    </div>
                    <table class="ggao_tab" cellpadding="0" cellspacing="0" v-if="infocontent.zbfilelist.length">
                        <tr>
                            <th class="ggao_tabthd">招标文件名称</th>
                            <th class="ggao_tabthd">标段名称</th>
                            <th class="ggao_tabthd">YCZB版下载</th>
                            <th class="ggao_tabthd">PDF版下载</th>
                            <th class="ggao_tabthd">其他下载</th>
                            <th class="ggao_tabthd">操作</th>
                        </tr>
                        <tr v-for="(v,i) in zbtableData" :key="i">
                            <td class="ggao_tabtrd">
                                <p style="margin-top: 15px;">
                                    {{v.zbfilename}}
                                    <span v-if="v.hascq && v.wjlx=='wj'" style="color:red">（请及时获取最新澄清文件）</span>
                                </p>
                            </td>
                            <td class="ggao_tabtrd"><p style="margin-top: 15px;">{{v.bdname}}</p></td>
                            <td class="ggao_tabtrd">
                                <!-- YCZB版下载 -->
                                <div>
                                    <template v-if="!v.isend && v.isbegin && v.hasyc !== '1'">
                                        <a
                                        :href="'/standalonfile/download/tender/hzb.do?rowguid='+ v.rowguid+'&m='+ Math.random()"
                                        style="color: #eb5c5c;"
                                        >
                                            下载
                                        </a>

                                        <p style="color:#999999">{{v.filesize}}MB</p>

                                        <p style="color:#999999">{{v.filesizekb}}KB</p>
                                    </template>

                                    <span
                                    v-if="(v.isend || !v.isbegin) && v.hasyc !== '1'"
                                    >
                                        该招标文件未在下载期限<p style="color:#999999">{{v.filesize}}MB</p>

                                        <p style="color:#999999">{{v.filesizekb}}KB</p>
                                    </span>

                                    <span v-if="v.hasyc === '1'">
                                        该标段已发布异常终止
                                    </span>
                                </div>
                            </td>
                            <td class="ggao_tabtrd">
                                <!-- PDF版下载 -->
                                <div>
                                    <a
                                    v-if="!v.isend && v.isbegin && v.hasyc !== '1'"
                                    :href="'/standalonfile/download/tender/pdf.do?rowguid='+v.rowguid+''"
                                    style="color: #eb5c5c;"
                                    >
                                        下载
                                    </a>

                                    <span
                                    v-if="(v.isend || !v.isbegin) && v.hasyc !== '1'"
                                    >
                                        该招标文件未在下载期限
                                    </span>

                                    <span v-if="v.hasyc === '1'">
                                        该标段已发布异常终止
                                    </span>
                                </div>
                            </td>
                            <td class="ggao_tabtrd">
                                <!-- 其他下载 -->
                                <div>
                                    <span v-if="(v.qtfj==null || v.qtfj=='') && v.hasyc !== '1'">无其他文件</span>
                                    <div v-if="(v.qtfj!=null || v.qtfj!='') && v.hasyc !== '1'">
                                        <p v-for="item in v.qtfjlist" :key="item.filename">
                                            <a :href="'/standalonfile/download/common.do?rowguid='+item.rowguid+''">{{item.filename}}</a>
                                        </p>
                                    </div>
                                    <span v-if="v.hasyc === '1'">
                                        该标段已发布异常终止
                                    </span>
                                </div>
                            </td>
                            <td class="ggao_tabtrd"><p><a style="color: #eb5c5c;cursor: pointer;" @click="edit(v.zbfilecode)">质疑</a></p></td>
                        </tr>
                    </table>
                </div>

                <!-- 澄清文件下载 -->
                <div v-if="infocontent.cqfilelist">
                    <p class="bgtit" v-if="infocontent.cqfilelist.length" style="padding-left: 15px; color: #2196f3;margin:0 auto;position: relative;height: 30px;line-height: 30px;font-weight: bold;border-bottom: 3px solid #2196f3;font-size: 15px;"><span style="height: 10px; width: 5px;background: #2196f3;border-radius: 5px;position: absolute;left: 0;top: 10px;"></span>澄清文件</p>

                    <div v-if="infocontent.cqfilelist.length"  style="width:92%;color:red;margin:0 auto;">
                        1.招标文件获取期限结束后将不能下载招标文件，请在下载完成后及时使用“投标文件制作工具”打开hzb格式招标文件，可查看招标文件正文即为完整有效。
                        <br/>
                        2.需妥善保存好验证过的“有效yczb格式招标文件”，没有“有效yczb格式招标文件”将无法制作投标文件。
                    </div>
                    <table class="ggao_tab" cellpadding="0" cellspacing="0" v-if="infocontent.cqfilelist.length">
                        <tr>
                            <th class="ggao_tabthd">招标文件名称</th>
                            <th class="ggao_tabthd">标段名称</th>
                            <th class="ggao_tabthd">YCZB版下载</th>
                            <th class="ggao_tabthd">PDF版下载</th>
                            <th class="ggao_tabthd">其他下载</th>
                            <th class="ggao_tabthd">操作</th>
                        </tr>
                        <tr v-for="(v,i) in cqtableData" :key="i">
                            <td class="ggao_tabtrd"><p style="margin-top: 15px;">{{v.zbfilename}}</p></td>
                            <td class="ggao_tabtrd"><p style="margin-top: 15px;">{{v.bdname}}</p></td>
                            <td class="ggao_tabtrd">
                                <!-- YCZB版下载 -->
                                <div>
                                    <template v-if="!v.isend && v.isbegin && v.hasyc !== '1'">
                                        <a
                                        :href="'/standalonfile/download/tender/hzb.do?rowguid='+ v.rowguid+'&m='+ Math.random()"
                                        style="color: #eb5c5c;"
                                        >
                                        下载
                                        </a>

                                        <p style="color:#999999">{{v.filesize}}MB</p>
                                        <p style="color:#999999">{{v.filesizekb}}KB</p>
                                    </template>

                                    <span v-if="(v.isend || !v.isbegin) && v.hasyc !== '1'">
                                        该澄清文件未在下载期限
                                        <p style="color:#999999">{{v.filesize}}MB</p>
                                        <p style="color:#999999">{{v.filesizekb}}KB</p>
                                    </span>

                                    <span v-if="v.hasyc === '1'">
                                        该标段已发布异常终止
                                    </span>
                                </div>
                            </td>
                            <td class="ggao_tabtrd">
                                <!-- PDF版下载 -->
                                <div>
                                    <a
                                    v-if="!v.isend && v.isbegin && v.hasyc !== '1'"
                                    :href="'/standalonfile/download/tender/pdf.do?rowguid='+v.rowguid+''"
                                    style="color: #eb5c5c;"
                                    >
                                        下载
                                    </a>

                                    <span v-if="(v.isend || !v.isbegin) && v.hasyc !== '1'">该澄清文件未在下载期限</span>

                                    <span v-if="v.hasyc === '1'">
                                        该标段已发布异常终止
                                    </span>
                                </div>
                            </td>
                            <td class="ggao_tabtrd">
                                <!-- 其他下载 -->
                                <div>
                                    <span v-if="(v.qtfj==null || v.qtfj=='') && v.hasyc !== '1'">无其他文件</span>

                                    <div v-if="(v.qtfj!=null || v.qtfj!='') && v.hasyc !== '1'">
                                        <p v-for="item in v.qtfjlist" :key="item.filename">
                                            <a :href="'/standalonfile/download/common.do?rowguid='+item.rowguid+''">{{item.filename}}</a>
                                        </p>
                                    </div>

                                    <span v-if="v.hasyc === '1'">
                                        该标段已发布异常终止
                                    </span>
                                </div>
                            </td>
                            <td class="ggao_tabtrd"><p><a style="color: #eb5c5c;cursor: pointer;" @click="edit(v.zbfilecode)">质疑</a></p></td>
                        </tr>
                    </table>
                </div>

                <!-- 补遗文件下载 -->
                <div v-if="infocontent.byfilelist">
                     <p class="bgtit" v-if="infocontent.byfilelist.length" style="padding-left: 15px; color: #2196f3;margin:0 auto;position: relative;height: 30px;line-height: 30px;font-weight: bold;border-bottom: 3px solid #2196f3;font-size: 15px;"><span style="height: 10px; width: 5px;background: #2196f3;border-radius: 5px;position: absolute;left: 0;top: 10px;"></span>补遗文件</p>

                    <table class="ggao_tab" cellpadding="0" cellspacing="0" v-if="infocontent.byfilelist.length">
                        <tr>
                            <th class="ggao_tabthd">招标文件名称</th>
                            <th class="ggao_tabthd">标段名称</th>
                            <th class="ggao_tabthd">文件下载</th>
                            <th class="ggao_tabthd">其他下载</th>
                            <th class="ggao_tabthd">操作</th>
                        </tr>
                        <tr v-for="(v,i) in bytableData" :key="i">
                            <td class="ggao_tabtrd"><p style="margin-top: 15px;">{{v.zbfilename}}</p></td>
                            <td class="ggao_tabtrd"><p style="margin-top: 15px;">{{v.bdname}}</p></td>
                            <td class="ggao_tabtrd">
                                <p>同招标文件（澄清文件）</p>
                            </td>
                            <td class="ggao_tabtrd">
                                <!-- 其他下载 -->
                                <div>
                                    <span v-if="(v.qtfj==null || v.qtfj=='') && v.hasyc !== '1'">无其他文件</span>

                                    <div v-if="(v.qtfj!=null || v.qtfj!='') && v.hasyc !== '1'">
                                        <p v-for="item in v.qtfjlist" :key="item.filename" class="wjdown">
                                            <a :href="'/standalonfile/download/common.do?rowguid='+item.rowguid+''">{{item.filename}}</a>
                                        </p>
                                    </div>

                                    <span v-if="v.hasyc === '1'">
                                        该标段已发布异常终止
                                    </span>
                                </div>
                            </td>
                            <td class="ggao_tabtrd"><p><a style="color: #eb5c5c;cursor: pointer;" @click="edit(v.zbfilecode)">质疑</a></p></td>
                        </tr>
                    </table>
                </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "conr",
  props:['infocontent','loading','categoryid'],
  data() {
    return {

    };
  },
  methods: {
    del(val,type){
      this.$router.push({
          path: '/trans/del',
          query: {
            id: val,
            type:type
          }
        });
    },
     edit(ZBFILECODE){
       console.log(ZBFILECODE);
              //  var that = this
              //  layer.open({
              //       type: 2,
              //       area: ['800px', '80%'],
              //       title:'质疑',
              //       content: 'zhiyi.html?filecode='+ ZBFILECODE,
              //       maxmin: true,
              //       cancel: function () {

              //       }
              //   });

      },
      down(val){
          window.location.href = '/standalonfile/download/tender/hzb.do?rowguid='+ val+'&m='+ Math.random()
      }

  },
};
</script>

<style  lang="less"  scoped>
.del-l {
  width: 930px;
}
.conr-bot{
 padding-bottom: 20px;
}
.tit{
  font-size: 20px;
  color: #333333;
  text-align: center;
   margin: 5px 0;
   font-weight: bold;

}
.xtit{
  color: #2196f3;
    margin: 0 auto;
    position: relative;
    height: 30px;
    line-height: 30px;
    font-weight: bold;
    font-size: 15px;
    padding-left: 15px;
    border-bottom: 3px solid #2196f3;
}
.del-mid{
  margin-top: 10px;
  background: @del-bg;
  padding: 10px 30px;
  .item{
    width: 50%;
    text-align: left;
    font-size: 14px;
    line-height: 30px;
    color: #747474;
    span{
      color: #010101;
      padding-left: 10px;
    }
  }
}
.del{
  margin-top: 15px;
}
</style>
